import { Grommet } from 'grommet';
import CookieBot from 'react-cookiebot';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './amplify-configure';
import { FrequentQuestions } from './components/organisms/frequent-questions/frequentQuestions';
import { LandingPage } from './components/pages/landing/landing-page';
import { CookiesPolicy } from './components/pages/legal/cookies-policy';
import { LegalNotice } from './components/pages/legal/legal-notice';
import { PrivacyPolicy } from './components/pages/legal/privacy-policy';
import { NewFlowPage } from './components/pages/order-flow/newFlow-page';
import { PriceTagPage } from './components/pages/price-tag/price-tag-page';
import { PurchasePage as PurchaseResultPage } from './components/pages/purchase-result/purchase-result-page';
import { VersionPage } from './components/pages/version/version-page';
import { MainTemplate } from './components/template/main-template/main-template';
import { GlobalStyle } from './components/template/main-template/main-templateStyles';
import { saveQueryParamas } from './utils/saveQueryParams';
import { SessionManager } from './utils/session-manager';
import NotFound from './components/pages/notFound/NotFound';
import { Contact } from './components/organisms/contact/contact';

const theme = {
  landing: {
    colors: {
      primary: '#263287',
      secondary: '#011553',
      third: '#06B192',
      fourth: '#FF4952',
      fifth: '#283288',
      lightBlue: '#84a9ff',
      yellow: '#fddf78',
      textNegative: '#F4F4F4',
      neutral: '#747474',
      negativeLighter: '#f6787f',
      negative: '#f54b53'
    }
  },
  global: {
    colors: {
      white: '#fff',
      brand: '#06B192',
      brandLight: '#e8ef97',
      brandDark: '#99a50c',
      secondary: '#8a784e',
      secondaryLight: '#524c2d',
      background: '#EDEDED',
      backgroundNeutral: '#fff',
      textColor: '#444444',
      positive: '#699e56',
      negative: '#d63f3f',
      neutral: '#747474',
      headingColor: '#fff',
      third: '#FF9E1B',
      focus: '#747474'
    },
    font: {
      family: 'sans-serif',
      size: '14px',
      height: '20px'
    }
  }
};

export const App = () => {
  saveQueryParamas(window.location.search);
  SessionManager.setUserId();
  return (
    <Grommet theme={theme} full>
      <Router>
        <CookieBot domainGroupId='382b8002-e8ef-4fef-aab3-d944bb34abe6' />;
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/version' element={<VersionPage />} />
          <Route path='/onboarding' element={<NewFlowPage />} />
          <Route
            path='/faq'
            element={
              <MainTemplate>
                <FrequentQuestions />
              </MainTemplate>
            }
          />
          <Route
            path='/contact'
            element={
              <MainTemplate>
                <Contact />
              </MainTemplate>
            }
          />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/legal-notice' element={<LegalNotice />} />
          <Route path='/cookies-policy' element={<CookiesPolicy />} />
          <Route path='/pricetag/:orderId' element={<PriceTagPage />} />
          <Route path='/purchase/:state' element={<PurchaseResultPage />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      <GlobalStyle />
    </Grommet>
  );
};
